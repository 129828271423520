import styles from '../../styles/claim.module.scss';
// eslint-disable-next-line import/named
import { useState } from 'react';
import { Button, FlatBox, Input } from 'majr-react';
import { motion, AnimatePresence } from 'framer-motion';
import validateBtcAddress from 'bitcoin-address-validation';

type GetBTCScreenProps = {
  children?: React.ReactNode;
};

type Inputs = {
  address: string;
};

export const GetBTCScreen: React.FC<GetBTCScreenProps> = () => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleRequest = async (data: Inputs) => {
    const isValid: boolean = validateBtcAddress(data.address);
    if (!isValid) {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 3000);
    } else {
      alert('Coming soon!');
    }
  };

  const [address, setAddress] = useState<string>('');
  const [confirm, setConfirm] = useState<boolean>(false);
  return (
    <section className={styles.claim}>
      <FlatBox width="20%" height="fit-content">
        <h2>Get BTC</h2>
        <Input
          value={address}
          onChange={setAddress}
          placeholder="Enter your BTC address"
        />
        <Button
          onClick={() => {
            setConfirm(true);
          }}
          text="Get BTC"
          variant="main"
          size="fillWidth"
        />
        <AnimatePresence>
          {isInvalid ? (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.error}
            >
              Invalid address
            </motion.p>
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {confirm ? (
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.confirmBackground}
            />
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {confirm ? (
            <motion.section
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'fit-content' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.confirm}
            >
              <p>Is this the correct address?</p>
              <p>{address}</p>
              <section>
                <Button
                  onClick={() => {
                    setConfirm(false);
                    handleRequest({ address });
                  }}
                  text="Yes"
                  variant="main"
                />
                <Button
                  onClick={() => {
                    setConfirm(false);
                  }}
                  text="No"
                  variant="light"
                />
              </section>
            </motion.section>
          ) : null}
        </AnimatePresence>
      </FlatBox>
      <p>
        MAJR is not responsible for assets being sent to the wrong address.{' '}
        <br />
        <br />
        The input of the wrong address will result in the loss of assets.
      </p>
    </section>
  );
};
