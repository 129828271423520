import { selectToken } from '../features/authSlice';
import { InfluencerState } from '../features/influencerSlice';
import { OAuthLogins } from '../models/OAuthLogins';
import { useAppSelector } from '../store/hooks';

// REACT_APP_BACKEND_URL
const API_URL = process.env.BACKEND_URL || 'https://api.c2e.dev.majr.io';

export const useInfluencerApi = () => {
  const token = useAppSelector(selectToken);

  const jsonHeader = {
    'Content-Type': 'application/json',
  };

  const authHeader = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const loginInfluencer = async (
    email: string,
    password: string
  ): Promise<{
    token: string;
  }> => {
    const response = await fetch(`${API_URL}/influencers/auth/signIn`, {
      body: JSON.stringify({ email, password, role: 'influencer' }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to login user.');
    }

    return response.json();
  };

  const registerInfluencer = async (
    email: string,
    password: string,
    name: string
  ): Promise<{
    token: string;
  }> => {
    const response = await fetch(`${API_URL}/influencers/auth/signUp`, {
      body: JSON.stringify({
        email,
        password,
        name,
        role: 'influencer',
        majrWallet: '0x008273E145f561aaCFA49BAb59313500F62c9Ec7',
      }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to login user.');
    }

    return response.json();
  };

  const loginAgency = async (
    email: string,
    password: string
  ): Promise<{
    token: string;
  }> => {
    const response = await fetch(`${API_URL}/agencies/auth/signIn`, {
      body: JSON.stringify({ email, password, role: 'agency' }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to login user.');
    }

    return response.json();
  };

  const registerAgency = async (
    email: string,
    password: string,
    name: string,
    phoneNumber: string
  ): Promise<{
    token: string;
  }> => {
    const response = await fetch(`${API_URL}/agencies/auth/signUp`, {
      body: JSON.stringify({
        email,
        password,
        name,
        phoneNumber,
        majrWallet: '0x008273E145f561aaCFA49BAb59313500F62c9Ec7',
        role: 'agency',
      }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to register user.');
    }

    return response.json();
  };

  const getInfluencerProfile = async (): Promise<{
    influencerDetails: InfluencerState;
  }> => {
    const response = await fetch(`${API_URL}/influencers/profile`, {
      headers: authHeader,
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Failed to get user profile');
    }

    return response.json();
  };

  const getInfluencersConnectedAccount = async (): Promise<{
    accounts: OAuthLogins[];
  }> => {
    const response = await fetch(
      `${API_URL}/accounts/influencer/connected/account`,
      {
        headers: authHeader,
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to get user profile');
    }

    return response.json();
  };

  const disconnectInfluencer = async (): Promise<{ success: string }> => {
    const response = await fetch(
      `${API_URL}/accounts/twitter/disconnect/influencer`,
      {
        headers: authHeader,
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to get user profile');
    }

    return response.json();
  };

  const handleRequestEth = async (
    address: string
  ): Promise<{ success: string }> => {
    const response = await fetch(
      `${API_URL}/influencers/request/eth/${address}`,
      {
        headers: authHeader,
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error('Failed to get user profile');
    }

    return response.json();
  };

  const forgotPassword = async (
    email: string
  ): Promise<{ success: string }> => {
    const response = await fetch(`${API_URL}/influencers/forgotPassword`, {
      body: JSON.stringify({ email }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    return response.json();
  };

  const resetPassword = async (
    password: string,
    resetToken: string,
    influencerId: number
  ): Promise<{ success: string }> => {
    const response = await fetch(`${API_URL}/influencers/resetPassword`, {
      body: JSON.stringify({ password, resetToken, influencerId }),
      headers: jsonHeader,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to reset password');
    }

    return response.json();
  };

  return {
    loginInfluencer,
    registerInfluencer,
    loginAgency,
    registerAgency,
    getInfluencerProfile,
    getInfluencersConnectedAccount,
    disconnectInfluencer,
    handleRequestEth,
    forgotPassword,
    resetPassword,
  };
};
