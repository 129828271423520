import { Routes, Route, Navigate } from 'react-router-dom';
import { selectToken } from '../features/authSlice';
import { AgenciesLoginScreen } from '../screens/agencies/login/AgenciesLoginScreen';
import { AgenciesRegisterScreen } from '../screens/agencies/register/AgenciesRegisterScreen';
import { DetailsScreen } from '../screens/details/DetailsScreen';
import { ForgotPasswordScreen } from '../screens/forgotPassword/ForgotPasswordScreen';
import { GetETHScreen } from '../screens/getEth/GetETHScreen';
import { GetBTCScreen } from '../screens/getEth/GetBTCScreen';
import { LoginScreen } from '../screens/login/LoginScreen';
import { RegisterScreen } from '../screens/register/RegisterScreen';
import { ResetPasswordScreen } from '../screens/resetPassword/ResetPasswordScreen';
import { useAppSelector } from '../store/hooks';
import { PartnerType } from '../common/Login/PartnerType';

export const CustomRoutes = () => {
  const token = useAppSelector(selectToken);

  return (
    <Routes>
      {token && token !== 'undefined' && token !== undefined && (
        <>
          <Route path="/rewards" element={<DetailsScreen />} />
          <Route path="/getEth" element={<GetETHScreen />} />
          <Route path="/getBtc" element={<GetBTCScreen />} />
        </>
      )}
      <Route
        path="/"
        element={
          token && token !== 'undefined' && token !== undefined ? (
            <DetailsScreen />
          ) : (
            <PartnerType />
          )
        }
      />
      {!token && <Route path="/sign-in" element={<PartnerType />} />}
      {!token && (
        <Route path="/creator" element={<PartnerType logintype="creator" />} />
      )}
      {!token && (
        <Route path="/agency" element={<PartnerType logintype="agency" />} />
      )}
      {!token && (
        <Route path="/brand" element={<PartnerType logintype="brand" />} />
      )}
      {!token && <Route path="/sign-up" element={<RegisterScreen />} />}
      {!token && (
        <Route path="/agencies/sign-in" element={<AgenciesLoginScreen />} />
      )}
      {!token && (
        <Route path="/agencies/sign-up" element={<AgenciesRegisterScreen />} />
      )}
      {!token && (
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      )}
      {!token && (
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
      )}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
