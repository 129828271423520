import React, { useEffect, useMemo, useState } from 'react';
import { IconVideo, IconBuilding, IconSpeakerphone } from '@tabler/icons';
import { Snackbar } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../common/Layout';
import { useAppDispatch } from '../../store/hooks';
import { setIsRegistered } from '../../features/authSlice';
import { useInfluencerApi } from '../../api/majr-api';
// eslint-disable-next-line import/named
import { Button, FlatBox, Input, Tag } from 'majr-react';
import styles from '../../styles/login.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { HeaderContext } from '../../context/header';
type RegisterScreenProps = {
  children?: React.ReactNode;
};

type Inputs = {
  email: string;
  password: string;
  fullName: string;
  phoneNumber?: string;
};

export const RegisterScreen: React.FC<RegisterScreenProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const { registerInfluencer, registerAgency } = useInfluencerApi();
  const [type, setType] = React.useState<'creator' | 'brand' | 'agency'>(
    'creator'
  );
  const getColor = (c: string): string => {
    return c === type ? '#DE1149' : '#38383D';
  };
  const [registered, setRegistered] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const handleSignUp = async (data: Inputs) => {
    if (type === 'creator') {
      await registerInfluencer(data.email, data.password, data.fullName)
        .then(() => {
          setRegistered(true);
          dispatch(setIsRegistered(true));
        })
        .catch((err) => {
          setOpen(true);
        });
    }
    if (type === 'agency') {
      await registerAgency(
        data.email,
        data.password,
        data.fullName,
        data.phoneNumber || ''
      )
        .then(() => {
          dispatch(setIsRegistered(true));
          navigate('/agency');
        })
        .catch((err) => {
          setOpen(true);
        });
    }
  };

  const onSubmit = async () => {
    console.log(type);
    if (type === 'creator') {
      await handleSignUp({
        email: email,
        password: password,
        fullName: name,
      });
    }
    if (type === 'agency') {
      await handleSignUp({
        email: email,
        password: password,
        fullName: name,
        phoneNumber,
      });
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setIsRegistered(false));
    setOpen(false);
  };

  const { changeState } = React.useContext(HeaderContext);
  useMemo(() => {
    changeState('Sign Up');
  }, [changeState]);
  const keyUpHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);
    return () => {
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, [onSubmit]);
  return (
    <Layout
      height={isMobile ? '62vh' : '72vh'}
      alignItems={'center'}
      justifyContent={'space-evenly'}
    >
      <FlatBox
        // onSubmit={handleSubmit(onSubmit)}
        width={isMobile ? '90%' : '25%'}
        height="fit-content"
        className={styles.signUp}
      >
        <h3>Sign Up - Creator</h3>
        <section className={styles.categorySignUp}>
          <Tag
            text="Creator"
            icon={<IconVideo color={getColor('creator')} />}
            theme="light"
            color={getColor('creator')}
            onClick={() => setType('creator')}
          />
          <Tag
            text="Brand"
            icon={<IconBuilding color={getColor('brand')} />}
            theme="light"
            color={getColor('brand')}
            onClick={() => setType('brand')}
          />
          <Tag
            text="Agency"
            icon={<IconSpeakerphone color={getColor('agency')} />}
            theme="light"
            color={getColor('agency')}
            onClick={() => setType('agency')}
          />
        </section>
        {type !== 'brand' ? (
          <>
            <Input
              onChange={setEmail}
              value={email}
              className=""
              placeholder="Enter email"
            />
            <Input
              onChange={setPassword}
              value={password}
              className=""
              placeholder="Enter password"
              variant="password"
            />
            <Input
              onChange={setName}
              value={name}
              className=""
              placeholder="Enter full name"
            />
            {type === 'agency' ? (
              <Input
                onChange={setPhoneNumber}
                value={phoneNumber}
                className=""
                placeholder="Enter phone number"
              />
            ) : null}

            <Button
              onClick={onSubmit}
              text="Create your account"
              variant="main"
              size="fillWidth"
            />
            <AnimatePresence>
              {registered ? (
                <>
                  <motion.div
                    className={styles.successBackground}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setRegistered(false)}
                  />
                  <motion.div
                    className={styles.success}
                    initial={{ opacity: 0, height: '0%' }}
                    animate={{
                      opacity: 1,
                      height: '60%',
                      transition: { duration: 0.5, easing: 'linear' },
                    }}
                    exit={{
                      opacity: 0,
                      height: '0%',
                      transition: { duration: 0.5, easing: 'linear' },
                    }}
                  >
                    <h3>Stay tuned</h3>
                    <p>A MAJR partner will be in touch shortly.</p>
                    <Button
                      onClick={() => navigate('/')}
                      text="Okay, Got it"
                      variant="main"
                      size="fillWidth"
                    />
                  </motion.div>
                </>
              ) : null}
              <button onClick={() => navigate('/sign-in')}>Sign In</button>
            </AnimatePresence>
          </>
        ) : (
          <p>Coming soon...</p>
        )}
      </FlatBox>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Email already taken!"
      />
    </Layout>
  );
};
