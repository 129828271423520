import { Link } from '@mui/material';
import styles from '../../styles/footer.module.scss';

type FooterProps = {
  children?: React.ReactNode;
};

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className={styles.footer}>
      <h3>MAJR INC</h3>
      <section>
        <Link
          href="https://goo.gl/maps/Y1L92uRbsamJC4dy8"
          rel="noopener noreferrer"
          target="_blank"
        >
          122 Sevilla Avenue, Coral Gables, FL 33134
        </Link>
        {/* <Link
          href="https://www.majr.io/about"
          rel="noopener noreferrer"
          target="_blank"
        >
          About
        </Link>
        <Link
          href="https://www.majr.io/about"
          rel="noopener noreferrer"
          target="_blank"
        >
          About
        </Link>
        <Link
          href="https://twitter.com/MAJR_DAO"
          rel="noopener noreferrer"
          target="_blank"
        >
          Twitter
        </Link>
        <Link href="mailto:support@majr.io">Support</Link>
        <Link
          href="https://docs.majrdao.io/vision/faq"
          rel="noopener noreferrer"
          target="_blank"
        >
          FAQ
        </Link>
        <Link
          href="https://www.majr.io/disclaimer"
          rel="noopener noreferrer"
          target="_blank"
        >
          Disclaimer
        </Link>
        <Link
          href="https://www.majr.io/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy
        </Link> */}
      </section>
    </footer>
  );
};
