export async function getETHPrice() {
  try {
    const response = await fetch(
      'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
    );
    const data = await response.json();
    return data.price;
  } catch (error) {
    // This is a fallback in case Binance API is down or returns an error
    const response = await fetch(
      'https://api.blockchain.com/v3/exchange/tickers/ETH-USD'
    );
    const data = await response.json();
    return data.price_24h;
  }
}

export async function getBTCPrice() {
  try {
    const response = await fetch(
      'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT'
    );
    const data = await response.json();
    return data.price;
  } catch (error) {
    // This is a fallback in case Binance API is down or returns an error
    const response = await fetch(
      'https://api.blockchain.com/v3/exchange/tickers/BTC-USD'
    );
    const data = await response.json();
    return data.price_24h;
  }
}
