import React from 'react';

interface AgencyGradientProps {
  active: boolean;
}
export const AgencyGradient = ({ active }: AgencyGradientProps) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath={`${
          active ? 'url(#clip0_1667_1961)' : 'url(#clip0_1613_1340)'
        }`}
      >
        <path
          d="M112.5 50C117.473 50 122.242 51.9754 125.758 55.4917C129.275 59.0081 131.25 63.7772 131.25 68.75C131.25 73.7228 129.275 78.4919 125.758 82.0083C122.242 85.5246 117.473 87.5 112.5 87.5M62.5 50V118.75C62.5 120.408 61.8415 121.997 60.6694 123.169C59.4973 124.342 57.9076 125 56.25 125H50C48.3424 125 46.7527 124.342 45.5806 123.169C44.4085 121.997 43.75 120.408 43.75 118.75V87.5M75 50.0002L103.275 26.4377C104.097 25.7533 105.096 25.3174 106.157 25.1808C107.217 25.0443 108.295 25.2128 109.263 25.6667C110.231 26.1206 111.05 26.8411 111.623 27.7436C112.196 28.6461 112.501 29.6934 112.5 30.7627V106.738C112.501 107.807 112.196 108.854 111.623 109.757C111.05 110.659 110.231 111.38 109.263 111.834C108.295 112.287 107.217 112.456 106.157 112.319C105.096 112.183 104.097 111.747 103.275 111.063L75 87.5002H25C23.3424 87.5002 21.7527 86.8417 20.5806 85.6696C19.4085 84.4975 18.75 82.9078 18.75 81.2502V56.2502C18.75 54.5926 19.4085 53.0028 20.5806 51.8307C21.7527 50.6586 23.3424 50.0002 25 50.0002H75Z"
          stroke={`${
            active
              ? 'url(#paint0_linear_1667_1961)'
              : 'url(#paint0_linear_1613_1340)'
          }`}
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id={active ? 'paint0_linear_1667_1961' : 'paint0_linear_1613_1340'}
          x1="18.75"
          y1="4.11051"
          x2="144.524"
          y2="107.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={active ? '#F9f9f9' : '#DE1149'} stopOpacity="0.2" />
          <stop
            offset="0.0001"
            stopColor={active ? '#F9f9f9' : '#DE1149'}
            stopOpacity={active ? '1' : '0.2'}
          />
          <stop
            offset="1"
            stopColor={active ? '#F9f9f9' : '#DE1149'}
            stopOpacity={active ? '0.2' : '0'}
          />
        </linearGradient>
        <clipPath id="clip0_1613_1340">
          <rect width="150" height="150" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
