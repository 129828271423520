import React, { useEffect, useMemo } from 'react';
import { IconVideo, IconBuilding, IconSpeakerphone } from '@tabler/icons';
import { FlatBox, Tag } from 'majr-react';
import { VideoGradient } from '../../assets/VideoGradient';
import styles from '../../styles/partner.module.scss';
import { BuildingGradient } from '../../assets/BuildingGradient';
import { AgencyGradient } from '../../assets/AgencyGradient';
import { Login } from './Login';
import { useNavigate } from 'react-router-dom';
import { HeaderContext } from '../../context/header';

interface PartnerTypeProps {
  logintype?: 'creator' | 'brand' | 'agency' | 'select';
}
export const PartnerType = ({ logintype = 'select' }: PartnerTypeProps) => {
  const navigate = useNavigate();
  const { changeState } = React.useContext(HeaderContext);
  const [iconsActive, setIconsActive] = React.useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [clicked, setClicked] = React.useState<number>(-1);
  const [showLogin, setShowLogin] = React.useState<boolean>(false);
  const [type, setType] = React.useState<'creator' | 'brand' | 'agency'>(
    'creator'
  );
  const getColor = (c: string): string => {
    return c === type ? '#DE1149' : '#38383D';
  };
  function handleClick(index: number) {
    setClicked(index);
    setIconsActive((prev) => {
      const newIconsActive = [...prev];
      newIconsActive[index] = true;
      return newIconsActive;
    });
  }
  const handleMouseLeave = (index: number) => {
    if (clicked === -1 && !showLogin) {
      setIconsActive((prev) => {
        const newIconsActive = [...prev];
        newIconsActive[index] = false;
        return newIconsActive;
      });
    }
  };

  const handleMouseEnter = (index: number) => {
    if (clicked === -1 && !showLogin) {
      setIconsActive((prev) => {
        const newIconsActive = [...prev];
        newIconsActive[index] = true;
        return newIconsActive;
      });
    }
  };
  const [titleState, setTitleState] = React.useState<{
    title: string;
    subtitle?: string;
    hide: boolean;
  }>({
    title: 'Welcome to MAJR partners',
    subtitle: 'Choose Your Partnership Type',
    hide: false,
  });
  const getClassName = (index: number): string => {
    const direction: string[] = [
      styles.partnerLeft,
      styles.partnerCenter,
      styles.partnerRight,
    ];
    return `
    ${clicked !== -1 ? direction[index] : ''} ${
      clicked === index && !showLogin ? styles.partnerActive : ''
    } ${showLogin ? styles.showLogin : ''}
    `;
  };
  useMemo(async () => {
    if (clicked !== -1) {
      setTitleState((prev) => {
        const newTitleState = { ...prev };
        newTitleState.hide = true;
        return newTitleState;
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setTitleState({
        title: 'Login',
        hide: false,
      });
      setShowLogin(true);
    } else {
      setShowLogin(false);
      setIconsActive([false, false, false]);
    }
  }, [clicked]);
  useMemo(() => {
    changeState('Login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (logintype !== 'select') {
      setType(logintype);
      handleClick(['creator', 'brand', 'agency'].indexOf(logintype));
    } else {
      if (logintype === 'select') {
        setTitleState({
          title: 'Welcome to MAJR partners',
          subtitle: 'Choose Your Partnership Type',
          hide: false,
        });
        setClicked(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return (
    <section className={styles.partnerContainer}>
      <h3
        className={`${titleState.hide ? styles.hideTitle : styles.showTitle}`}
      >
        {titleState.title}
      </h3>
      <p>{titleState.subtitle ? titleState.subtitle : ''}</p>
      {showLogin ? (
        <section className={styles.category}>
          <Tag
            text="Creator"
            icon={<IconVideo color={getColor('creator')} />}
            theme="light"
            color={getColor('creator')}
            onClick={() => {
              navigate('/creator');
              setType('creator');
            }}
          />
          <Tag
            text="Brand"
            icon={<IconBuilding color={getColor('brand')} />}
            theme="light"
            color={getColor('brand')}
            onClick={() => {
              navigate('/brand');
              setType('brand');
            }}
          />
          <Tag
            text="Agency"
            icon={<IconSpeakerphone color={getColor('agency')} />}
            theme="light"
            color={getColor('agency')}
            onClick={() => {
              navigate('/agency');
              setType('agency');
            }}
          />
        </section>
      ) : null}
      <section className={styles.partner}>
        <FlatBox
          width="100%"
          height="40vh"
          onMouseLeave={() => handleMouseLeave(0)}
          onMouseEnter={() => handleMouseEnter(0)}
          className={getClassName(0)}
          onClick={() => {
            handleClick(0);
            navigate('/creator');
          }}
        >
          {!showLogin ? (
            <>
              <h3>Creator</h3>
              <VideoGradient active={iconsActive[0]} />
            </>
          ) : null}
        </FlatBox>
        <FlatBox
          width="100%"
          height="40vh"
          onMouseLeave={() => handleMouseLeave(1)}
          onMouseEnter={() => handleMouseEnter(1)}
          className={getClassName(1)}
          onClick={() => {
            handleClick(1);
            navigate('/brand');
          }}
        >
          {!showLogin ? (
            <>
              <h3>Brand</h3>
              <BuildingGradient active={iconsActive[1]} />
            </>
          ) : null}
        </FlatBox>
        <FlatBox
          width="100%"
          height={showLogin ? 'max(40vh, 350px)' : '40vh'}
          onMouseLeave={() => handleMouseLeave(2)}
          onMouseEnter={() => handleMouseEnter(2)}
          onClick={() => {
            handleClick(2);
            navigate('/agency');
          }}
          className={getClassName(2)}
        >
          {!showLogin ? (
            <>
              <h3>Agency</h3>
              <AgencyGradient active={iconsActive[2]} />
            </>
          ) : (
            <Login type={type} />
          )}
        </FlatBox>
      </section>
    </section>
  );
};
