import { Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputField } from '../../common/InputField/InputField';
import { CustomButton } from '../../common/CustomButton/CustomButton';
import { themeColors } from '../../theme/schemas/MajrLightTheme';
import { Layout } from '../../common/Layout';

import { useInfluencerApi } from '../../api/majr-api';
// eslint-disable-next-line import/named
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

type LoginScreenProps = {
  children?: React.ReactNode;
};
type Inputs = {
  password: string;
};

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const ResetPasswordScreen: React.FC<LoginScreenProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const resetToken = useQuery().get('resetToken') || '';
  const influencerId = useQuery().get('influencer') || '';

  const navigate = useNavigate();

  const { resetPassword } = useInfluencerApi();

  const handleResetPassword = async (data: Inputs) => {
    await resetPassword(data.password, resetToken, parseInt(influencerId))
      .then(() => {
        navigate('/');
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await handleResetPassword(data);
  };

  return (
    <Layout
      height={isMobile ? '62vh' : '72vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '95%' : '25%',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            alignSelf: 'flex-start',
            color: themeColors.darkGreyText,
            fontSize: isMobile ? '16px' : '24px',
            textTransform: 'uppercase',
            fontFamily: 'Nasalization',
            fontWeight: 100,
            height: isMobile ? '24px' : '40px',
            marginLeft: isMobile ? '20px' : '10px',
            textAlign: 'left',
          }}
        >
          Reset password
        </Typography>

        {errors.password && (
          <span
            role="alert"
            style={{
              color: themeColors.primary,
              margin: '0px',
              marginBottom: '5px',
            }}
          >
            Password is required
          </span>
        )}
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <InputField
              type="password"
              width={'100%'}
              margin={'0px 20px 20px 15px'}
              placeholder={'Password'}
              {...field}
            />
          )}
        />

        <CustomButton textAlign={'center'} title={'Reset Password'} />
      </form>
    </Layout>
  );
};
