import { useMediaQuery } from 'react-responsive';
import { themeColors } from '../theme/schemas/MajrLightTheme';

type Props = {
  alignItems?: string;
  children?: React.ReactNode;
  flexDirection?: 'row' | 'column';
  justifyContent?: string;
  height?: string | number;
  width?: string | number;
  flexWrap?: any;
};

export const Layout: React.FC<Props> = ({
  children,
  flexDirection,
  height,
  width,
  alignItems,
  justifyContent,
  flexWrap,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <div
      style={{
        alignItems,
        justifyContent,
        backgroundColor: themeColors.white,
        display: 'flex',
        flexDirection,
        height,
        width: isMobile ? '100wh' : width,
        overflow: 'hidden',
        margin: '20px',
        paddingTop: '100px',
        paddingBottom: '20px',
        flexWrap: flexWrap,
      }}
    >
      {' '}
      {children}
    </div>
  );
};

Layout.defaultProps = {
  children: null,
  flexDirection: 'column',
  height: '100%',
  width: '95vw',
};
