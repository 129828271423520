import React from 'react';

interface BuildingGradientProps {
  active: boolean;
}
export const BuildingGradient = ({ active }: BuildingGradientProps) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={'url(#clip0_1613_1272)'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.0901 15.7727C84.1219 16.4104 84.75 17.537 84.75 18.75V41.8769L120.691 65.8378C121.665 66.487 122.25 67.5798 122.25 68.75V127.75H131.25C133.183 127.75 134.75 129.317 134.75 131.25C134.75 133.183 133.183 134.75 131.25 134.75H118.75H81.25H31.25H18.75C16.817 134.75 15.25 133.183 15.25 131.25C15.25 129.317 16.817 127.75 18.75 127.75H27.75V43.75C27.75 42.4243 28.499 41.2124 29.6848 40.6195L79.6848 15.6195C80.7697 15.077 82.0582 15.135 83.0901 15.7727ZM115.25 127.75V70.6231L84.75 50.2898V127.75H115.25ZM77.75 43.6957C77.7495 43.7298 77.7495 43.7638 77.75 43.7979V127.75H34.75V45.9131L77.75 24.4131V43.6957ZM56.25 52.75C58.183 52.75 59.75 54.317 59.75 56.25V56.3125C59.75 58.2455 58.183 59.8125 56.25 59.8125C54.317 59.8125 52.75 58.2455 52.75 56.3125V56.25C52.75 54.317 54.317 52.75 56.25 52.75ZM59.75 75C59.75 73.067 58.183 71.5 56.25 71.5C54.317 71.5 52.75 73.067 52.75 75V75.0625C52.75 76.9955 54.317 78.5625 56.25 78.5625C58.183 78.5625 59.75 76.9955 59.75 75.0625V75ZM56.25 90.25C58.183 90.25 59.75 91.817 59.75 93.75V93.8125C59.75 95.7455 58.183 97.3125 56.25 97.3125C54.317 97.3125 52.75 95.7455 52.75 93.8125V93.75C52.75 91.817 54.317 90.25 56.25 90.25ZM59.75 112.5C59.75 110.567 58.183 109 56.25 109C54.317 109 52.75 110.567 52.75 112.5V112.563C52.75 114.496 54.317 116.063 56.25 116.063C58.183 116.063 59.75 114.496 59.75 112.563V112.5Z"
          fill={
            active
              ? 'url(#paint0_linear_1613_1272)'
              : 'url(#paint0_linear_1613_1330)'
          }
        />
      </g>
      <defs>
        <linearGradient
          id={active ? 'paint0_linear_1613_1272' : 'paint0_linear_1613_1330'}
          x1="17.75"
          y1="-6.35526"
          x2="157.801"
          y2="96.3319"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.0001"
            stopColor={active ? '#F9F9F9' : '#DE1149'}
            stopOpacity={active ? '1' : '0.2'}
          />
          <stop
            offset="1"
            stopColor={active ? '#F9F9F9' : '#DE1149'}
            stopOpacity={active ? '0.2' : '0'}
          />
        </linearGradient>
        <clipPath id="clip0_1613_1272">
          <rect width="150" height="150" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
