import React, { useMemo } from 'react';

import { Button, Input } from 'majr-react';
import styles from '../../styles/login.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { useInfluencerApi } from '../../api/majr-api';
import { setIsLoggedIn, setToken } from '../../features/authSlice';
import { AnimatePresence, motion } from 'framer-motion';

type Inputs = {
  email: string;
  password: string;
};
interface LoginProps {
  type: 'creator' | 'brand' | 'agency';
}
export const Login = ({ type }: LoginProps) => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loginInfluencer, loginAgency } = useInfluencerApi();

  const [wrongLogin, setWrongLogin] = React.useState(false);
  const [stayTuned, setStayTuned] = React.useState(false);

  const handleSignIn = async (data: Inputs) => {
    if (type === 'creator') {
      await loginInfluencer(data.email, data.password)
        .then((res: { token: string }) => {
          localStorage.setItem('token', res.token);
          dispatch(setIsLoggedIn(true));
          dispatch(setToken(res.token));
          navigate('/rewards');
        })
        .catch((err: unknown) => {
          setWrongLogin(true);
        });
    }
    if (type === 'agency') {
      await loginAgency(data.email, data.password)
        .then((res: { token: string }) => {
          localStorage.setItem('token', res.token);
          dispatch(setIsLoggedIn(true));
          dispatch(setToken(res.token));
          navigate('/rewards');
        })
        .catch((err: unknown) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setWrongLogin(true);
        });
    }
  };
  const handleLogin = async () => {
    await handleSignIn({ email, password });
  };

  useMemo(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    });
    return () => {
      document.removeEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          handleLogin();
        }
      });
    };
  }, []);

  return (
    <section className={styles.login} onClick={(e) => e.stopPropagation()}>
      <Input
        onChange={setEmail}
        value={email}
        placeholder="Enter your email"
        disabled={type === 'brand'}
      />
      <Input
        onChange={setPassword}
        value={password}
        placeholder="Enter your Password"
        variant="password"
        disabled={type === 'brand'}
      />
      {type === 'brand' ? (
        <p>Coming soon...</p>
      ) : (
        <>
          <button
            className={styles.login__button}
            onClick={() => {
              navigate('/forgot-password');
            }}
          >
            Forgot Password?
          </button>
          <Button
            text="Login"
            variant="main"
            onClick={handleLogin}
            size="fillWidth"
          />
        </>
      )}
      <p>{wrongLogin ? <span>Wrong login</span> : <span></span>}</p>
      <AnimatePresence>
        {stayTuned && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.login__stayTunedBackground}
            onClick={() => handleLogin()}
          />
        )}
      </AnimatePresence>
    </section>
  );
};
