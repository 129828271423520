import React, { useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
  InfluencerState,
  selectInfluencer,
  setUserInfo,
} from '../../features/influencerSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  selectIsLoggedIn,
  selectToken,
  setIsLoggedIn,
} from '../../features/authSlice';
import { useInfluencerApi } from '../../api/majr-api';
import styles from '../../styles/creators.module.scss';
import { FlatBox, Button, IconButton } from 'majr-react';
import {
  IconX,
  IconCopy,
  IconExternalLink,
  IconBrandTwitter,
  IconId,
  IconUserCircle,
} from '@tabler/icons';

import { AnimatePresence, motion } from 'framer-motion';
import { getBTCPrice, getETHPrice } from '../../utils/quotes';
import { formatCurrency } from '../../utils/format';
import { HeaderContext } from '../../context/header';
type DetailsScreenProps = {
  children?: React.ReactNode;
};
const TWITTER_OAUTH_LINK = `https://api.c2e.dev.majr.io/auth/twitter`;

const CREATIVE_LINK =
  'https://drive.google.com/drive/folders/1ZtQWJ8r8vnmEhJz1j6ZmtQ6u3cLNCNGn';
export const DetailsScreen: React.FC<DetailsScreenProps> = () => {
  const token = useAppSelector(selectToken);

  const navigate = useNavigate();
  const influencer = useAppSelector(selectInfluencer);
  const [userDetails, setUserDetails] = useState(influencer);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const [open, setOpen] = useState(isLoggedIn || false);
  const [twitterDisconnect, setTwitterDisconnect] = useState(false);

  const hypeLink = `https://get.majr.io?ref=${userDetails?.refCode}` || '';
  const creators: {
    name: string;
    email: string;
    signUps: number;
    earned: number;
  }[] = [
    {
      name: 'Tomas',
      email: 'info@tdm.ar',
      signUps: 13,
      earned: 0.7,
    },
    {
      name: 'Tomas2',
      email: 'tomas2@tdm.ar',
      signUps: 13,
      earned: 0.7,
    },
  ];
  const campaigns: {
    name: string;
    id: number;
    startDate: string;
    endDate: string;
    budget: number;
    rebate: number;
  }[] = [
    {
      name: 'Campaign',
      id: 1010,
      startDate: '2021-10-10',
      endDate: '2021-10-10',
      budget: 0.7,
      rebate: 0.035,
    },
    {
      name: 'Test',
      id: 1009,
      startDate: '2021-10-10',
      endDate: '2021-10-10',
      budget: 0.01,
      rebate: 0.001,
    },
    {
      name: 'Campaign',
      id: 1010,
      startDate: '2021-10-10',
      endDate: '2021-10-10',
      budget: 11.53,
      rebate: 0.7,
    },
  ];
  const [openWalletInfo, setOpenWalletInfo] = useState(false);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setIsLoggedIn(false));
    setOpen(false);
  };

  const {
    getInfluencerProfile,
    disconnectInfluencer,
    getInfluencersConnectedAccount,
  } = useInfluencerApi();
  const copyAddress = () => {
    navigator.clipboard.writeText(userDetails?.majrWallet || '');
  };
  const openInEtherscan = () => {
    window.open(
      `https://etherscan.io/address/${userDetails?.majrWallet}`,
      '_blank'
    );
  };

  React.useMemo(() => {
    if (
      localStorage.getItem('token') &&
      token &&
      token !== 'undefined' &&
      token !== undefined
    ) {
      getInfluencerProfile().then(
        (response: { influencerDetails: InfluencerState }) => {
          const inf = {
            email: response.influencerDetails.email,
            name: response.influencerDetails.name,
            token: response.influencerDetails.token,
            majrWallet: response.influencerDetails.majrWallet,
            wallet: response.influencerDetails.wallet,
            refCode: response.influencerDetails.refCode,
            twitter: response.influencerDetails.twitter,
            creativeAssets: response.influencerDetails.creativeAssets,
            signUps: response.influencerDetails.signUps,
            payouts: response.influencerDetails.payouts,
            totalAmount: response.influencerDetails.totalAmount,
            agencyId: response.influencerDetails.agencyId,
          };
          setUserDetails(inf);
          dispatch(setUserInfo(inf));
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);
  const [brand, setBrand] = React.useState<boolean>(false);
  const { changeState } = React.useContext(HeaderContext);
  const [btcPrice, setBtcPrice] = useState<number>(0);
  useMemo(() => {
    changeState('Logged In');
    const connectedAccounts = async () => {
      await getInfluencersConnectedAccount();
    };
    connectedAccounts();
    getETHPrice().then((res) => {
      setEthPrice(res);
    });
    getBTCPrice().then((res) => {
      setBtcPrice(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ethPrice, setEthPrice] = useState<number>(0);
  const [openCreators, setOpenCreators] = useState<boolean>(false);
  return (
    <>
      <section className={styles.creators}>
        {!brand ? (
          <h2 className={styles.creators__title}>
            Welcome back,{' '}
            {userDetails?.name ? userDetails?.name.split(' ')[0] : ''}{' '}
            <img src="/hiemoji.webp" alt="emoji" />
          </h2>
        ) : (
          <h2 className={styles.creators__title}>
            {userDetails?.name ? userDetails?.name.split(' ')[0] : ''} Campaigns
            🛠️
          </h2>
        )}
        {!brand ? (
          <FlatBox
            width="100%"
            height="fit-content"
            className={styles.creators__details}
          >
            <h3 className={styles.details__wallet}>
              Wallet Address
              <InfoOutlinedIcon
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenWalletInfo(!openWalletInfo)}
              />
            </h3>
            <AnimatePresence>
              {openWalletInfo && (
                <motion.p
                  className={styles.details__info}
                  initial={{ opacity: 0, height: '0%', overflow: 'hidden' }}
                  animate={{
                    opacity: 1,
                    height: 'fit-content',
                    overflow: 'hidden',
                    transition: { duration: 0.5, ease: 'linear', delay: 0 },
                  }}
                  exit={{ opacity: 1, height: '0%', overflow: 'hidden' }}
                >
                  This is a multi-sig wallet set up by MAJR for all MAJR Creator
                  Partners. It is for convenience and security. It is not your
                  personal wallet. Do not use to send or receive funds from
                  different from different accounts.{' '}
                  <IconX
                    size={20}
                    color="#38383D"
                    onClick={() => setOpenWalletInfo(!openWalletInfo)}
                  />
                </motion.p>
              )}
            </AnimatePresence>
            <section className={styles.details__walletAddressWrapper}>
              <h3 className={styles.details__walletAddress}>
                {userDetails?.majrWallet}
              </h3>
              <IconButton
                icon={<IconCopy color="#de1149" />}
                onClick={copyAddress}
              />
              <IconButton
                icon={<IconExternalLink color="#de1149" />}
                onClick={openInEtherscan}
              />
            </section>
            <h3 className={styles.details__subtitle}>Hype link</h3>
            <div className={styles.details__hypeLinkContainer}>
              <h3 className={styles.details__hypeLink}>
                {hypeLink}
                <IconCopy color="#de1149" />
              </h3>
              {userDetails?.agencyId ? (
                <Button
                  text={'See your creators'}
                  onClick={() => {
                    setOpenCreators(!openCreators);
                  }}
                  variant="light"
                />
              ) : (
                <Button
                  icon={<IconBrandTwitter color="#f9f9f9" />}
                  text="Share on Twitter"
                  onClick={() => {
                    window.open(
                      `http://twitter.com/share?text=Earn ETH by watching YouTube, We help Creators and Viewers generate value through new video experiences.&url=${hypeLink}&hashtags=MAJRDAO,Creators,Viewers`
                    );
                  }}
                  iconPosition="left"
                  variant="main"
                />
              )}
            </div>
            {!userDetails?.agencyId ? (
              <div className={styles.details__actions}>
                <Button
                  text="Get Memberships"
                  onClick={() =>
                    window.open(
                      'https://dashboard.majrdao.io/mintid',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                  variant="light"
                />
                <Button
                  text="Creative assets"
                  onClick={() =>
                    window.open(CREATIVE_LINK, '_blank', 'noopener,noreferrer')
                  }
                  variant="light"
                />
              </div>
            ) : null}
            <AnimatePresence>
              {openCreators && userDetails?.agencyId ? (
                <motion.div
                  className={styles.details__creators}
                  initial={{ opacity: 0, height: '0%' }}
                  animate={{
                    opacity: 1,
                    height: 'fit-content',
                    transition: { duration: 0.5, ease: 'linear', delay: 0 },
                  }}
                  exit={{ opacity: 1, height: '0%' }}
                >
                  <h3>Creators</h3>
                  <section className={styles.details__creatorsHeadings}>
                    <p>Name</p>
                    <p>Email</p>
                    <p>Sign Up</p>
                    <p>Earned</p>
                  </section>
                  <section className={styles.details__creatorsList}>
                    {creators.map(
                      (creator: {
                        name: string;
                        email: string;
                        signUps: number;
                        earned: number;
                      }) => (
                        <div
                          className={styles.creatorsList__item}
                          key={creator.email}
                        >
                          <h3>{creator.name}</h3>
                          <p>{creator.email}</p>
                          <IconUserCircle color="#38383D" size={30} />
                          <h3>{creator.signUps}</h3>
                          <h4>+{creator.earned} ETH</h4>
                          <p>
                            ~{formatCurrency(creator.earned * ethPrice)} USD
                          </p>
                        </div>
                      )
                    )}
                  </section>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </FlatBox>
        ) : (
          <section className={styles.creators__campaigns}>
            <section className={styles.creators__campaignsWallet}>
              <p>Your wallet</p>
              <div>
                <h3>{userDetails?.majrWallet}</h3>
                <IconButton
                  icon={<IconCopy color="#de1149" />}
                  onClick={copyAddress}
                />
              </div>
            </section>
            <section>
              {campaigns.map((campaign: any) => (
                <section className={styles.campaigns__item} key={campaign.id}>
                  <div>
                    <h3>
                      {campaign.name} #{campaign.id}
                    </h3>
                    <p>
                      {campaign.startDate} - {campaign.endDate}
                    </p>
                    <h4>Budget: {campaign.budget} BTC</h4>
                  </div>
                  <div>
                    <h3>Rebate: {campaign.rebate} BTC</h3>
                    <p>~{formatCurrency(campaign.rebate * btcPrice)} USD</p>
                  </div>
                </section>
              ))}
            </section>
          </section>
        )}
        <FlatBox
          width={'100%'}
          height="450px"
          className={styles.creators__earnings}
        >
          <h2>{brand ? 'Rebates' : 'Earnings'}</h2>
          {/* Remove on prod */}
          <button
            onClick={() => {
              setBrand(!brand);
            }}
          >
            Creator/Brand
          </button>
          <button
            onClick={() => {
              setUserDetails((prev) => ({
                ...prev,
                agencyId: prev?.agencyId ? undefined : 1,
              }));
            }}
          >
            Agency on/off
          </button>
          <section className={styles.earnings__container}>
            {brand ? (
              <>
                <h3>{formatCurrency(userDetails?.payouts * btcPrice)} USD</h3>
                <p>{userDetails?.payouts} BTC</p>
              </>
            ) : (
              <>
                <h3>{formatCurrency(userDetails?.payouts * ethPrice)} USD</h3>
                <p>{userDetails?.payouts} ETH</p>
              </>
            )}
          </section>
          {!brand && (
            <section className={styles.earnings__stats}>
              <section className={styles.stats__item}>
                <div>
                  <IconUserCircle color="#38383D" />
                  <h3>Sign Ups</h3>
                </div>
                <h3>{userDetails?.signUps}</h3>
              </section>
              <section className={styles.stats__item}>
                <div>
                  <IconId color="#38383D" />
                  <h3>Mints</h3>
                </div>
                <h3>{userDetails?.totalAmount}</h3>
              </section>
            </section>
          )}
          <Button
            variant="light"
            text={brand ? 'Request BTC' : 'Request ETH'}
            size="fillWidth"
            onClick={() => {
              if (brand) {
                navigate('/getBtc');
              } else {
                navigate('/getEth');
              }
            }}
            className={brand ? styles.earnings__button : ''}
          />
        </FlatBox>
      </section>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Logged in successfully!"
      />
      {twitterDisconnect && (
        <Snackbar
          open={twitterDisconnect}
          autoHideDuration={2000}
          onClose={() => setTwitterDisconnect(false)}
          message="Twitter disconnected successfully!"
        />
      )}
    </>
  );
};
