import { Button } from '@mui/material';
import { themeColors } from '../../theme/schemas/MajrLightTheme';

type CustomButtonProps = {
  children?: React.ReactNode;
  title: string;
  textAlign?: 'left' | 'center' | 'right';
  width?: string | number;
  handleOnClick?: () => void;
};

export const CustomButton: React.FC<CustomButtonProps> = ({
  textAlign,
  title,
  handleOnClick,
}) => {
  return (
    <Button
      variant="outlined"
      onClick={handleOnClick}
      type="submit"
      style={{
        borderRadius: '100px',
        color: themeColors.black,
        width: '100%',
        borderColor: themeColors.black,
        textAlign: textAlign,
        boxShadow: '0 8px 8px -4px #DE1149',
      }}
    >
      {title}
    </Button>
  );
};
