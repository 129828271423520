import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'majr-react';
import { HeaderContext } from '../../context/header';
import { setIsLoggedIn, setToken } from '../../features/authSlice';
import { useDispatch } from 'react-redux';
import styles from '../../styles/header.module.scss';
import { IconLogout, IconSettings } from '@tabler/icons';
import { Drawer } from './Drawer';

type HeaderProps = {
  children?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { state, setDrawer } = React.useContext(HeaderContext);
  const dispatch = useDispatch();

  return (
    <>
      <nav className={styles.nav}>
        <h3
          className={styles.nav__logo}
          onClick={() => {
            navigate('/');
          }}
        >
          MAJR
        </h3>
        {state === 'Login' ? (
          <Button
            onClick={() => navigate('/sign-up')}
            text="Sign Up"
            className={styles.login__signUp}
            variant="light"
          />
        ) : null}
        {state === 'Sign Up' ? (
          <Button
            onClick={() => navigate('/')}
            text="Log In"
            className={styles.login__signUp}
            variant="light"
          />
        ) : null}
        {state === 'Logged In' ? (
          <div>
            <Button
              onClick={() => {
                localStorage.removeItem('token');
                dispatch(setIsLoggedIn(false));
                dispatch(setToken(''));
              }}
              icon={<IconLogout color="#de1149" />}
              iconPosition="right"
              text="Logout"
              className={styles.login__signUp}
              variant="light"
            />
            <Button
              onClick={() => {
                setDrawer(true);
              }}
              icon={<IconSettings color="#de1149" />}
              iconPosition="right"
              text="Settings"
              className={styles.login__signUp}
              variant="light"
            />
          </div>
        ) : null}
      </nav>
      <Drawer />
    </>
  );
};
