import { createTheme } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type {} from '@mui/x-data-grid/themeAugmentation';

// import i18n from 'src/i18n/i18n';
export const themeColors = {
  primary: '#DE1149',
  primaryDark: '#AD002F',
  primaryWithOpacity: `rgba(222, 17, 73, 0.1)`,
  secondary: '#F2F2F2',
  success: '#27AE60',
  successWithOpacity: `rgba(39, 174, 96, 0.1)`,
  warning: '#FFA319',
  pending: '#F6C143',
  pendingWithOpacity: 'rgba(246, 193, 67, 0.1)',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57',
  darkGreyText: '#403E54',
  darkGreyTextWithOpacity: `rgba(64, 62, 84, 0.1)`,
  darkGreyTextTransparency: `rgba(64, 62, 84, 0.5)`,
  lightGreyText: '#606470',
  lightGreyTable: '#B1B5C6',
  darkGrey: '#8A8994',
  chartTextGrey: '#858C94',
  lightGrey: '#F2F2F2',
  chartLightGrey: '#EEEEEE',
  lightGreyBackground: '#E5E5E5',
  lightGreyIcons: 'rgba(64, 62, 84, 0.5)',
  paleLightGreen: 'rgba(39, 174, 96, 0.1)',
  greenSection: '#27AE60',
  palePinkSection: 'rgba(222, 17, 73, 0.1)',
  radioButtonGrey: '#A0A5B9',
  uploadGrey: '#EDEDED',
};

export const MajrLightTheme = createTheme({
  palette: {
    primary: {
      light: themeColors.primaryWithOpacity,
      main: themeColors.primary,
      dark: themeColors.primaryDark,
      contrastText: themeColors.primary,
    },
    secondary: {
      light: '#0066ff',
      main: themeColors.secondary,
      contrastText: '#ffcc00',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
        },
        h1: {
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '15px',
          color: themeColors.darkGreyText,
        },
        h2: {
          alignSelf: 'center',
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '14px',
          background:
            '-webkit-linear-gradient(-90deg, #403E54 -77%, #F2F2F2 145%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        h3: {
          alignSelf: 'center',
          fontWeight: '400',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontSize: '16px',
          color: themeColors.darkGreyText,
        },
        body1: {
          alignSelf: 'center',
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '14px',
          color: themeColors.primary,
        },
        body2: {
          alignSelf: 'center',
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '14px',
          color: themeColors.darkGreyTextTransparency,
        },
        h5: {
          alignSelf: 'center',
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '14px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: 'Nasalization',
          backgroundColor: themeColors.primary,
          fontSize: '16px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: '400',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          paddingLeft: 16,
          paddingRight: 16,
          height: '45px',
          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },
        },
        endIcon: {
          marginRight: -8,
        },
        containedPrimary: {
          backgroundColor: themeColors.primary,
          color: themeColors.white,
          fontSize: '14px',
          lineHeight: '16.63px',
          height: '45px',
          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },
          boxShadow: [
            '-5px 5px 10px rgba(216, 216, 219, 0.2)',
            '5px -5px 10px rgba(216, 216, 219, 0.2)',
            '-5px -5px 10px rgba(255, 255, 255, 0.9)',
            '5px 5px 13px rgba(216, 216, 219, 0.9)',
            'inset 1px 1px 2px rgba(255, 255, 255, 0.3)',
            'inset -1px -1px 2px rgba(216, 216, 219, 0.5)',
          ],
          borderRadius: '10px',
          ':disabled': {
            backgroundColor: themeColors.palePinkSection,
          },
        },
        containedSecondary: {
          backgroundColor: themeColors.white,
          fontSize: '14px',
          lineHeight: '16.63px',
          height: '45px',
          boxShadow: [
            '-5px 5px 10px rgba(216, 216, 219, 0.2)',
            '5px -5px 10px rgba(216, 216, 219, 0.2)',
            '-5px -5px 10px rgba(255, 255, 255, 0.9)',
            '5px 5px 13px rgba(216, 216, 219, 0.9)',
            'inset 1px 1px 2px rgba(255, 255, 255, 0.3)',
            'inset -1px -1px 2px rgba(216, 216, 219, 0.5)',
          ],
          borderRadius: '10px',
          '&:hover, &.MuiSelected': {
            backgroundColor: themeColors.white,
            color: themeColors.lightGrey,
          },
        },
        outlinedSecondary: {
          backgroundColor: themeColors.darkGrey,
          fontSize: '14px',
          lineHeight: '16.63px',
          height: '45px',
          borderRadius: '10px',
          '&:hover, &.MuiSelected': {
            backgroundColor: themeColors.darkGrey,
            color: themeColors.lightGrey,
          },
        },
        sizeSmall: {
          padding: '6px 16px',
          lineHeight: 1.5,
        },
        sizeMedium: {
          padding: '8px 20px',
        },
        sizeLarge: {
          padding: '11px 24px',
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '9px 16px',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Nasalization',
          fontSize: '12px',
          marginBottom: '8px',
          background:
            '-webkit-linear-gradient(-90deg, #403E54 -77%, #F2F2F2 145%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          margin: '0px',
          '&:before': {
            borderBottom: 'hidden',
          },
          '&:after': {
            borderBottom: 'hidden',
          },
          '&:hover': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          padding: '14px',
          paddingLeft: '8%',
          fontFamily: 'Gilroy',
          fontSize: '16.56px',
          color: themeColors.darkGreyText,
        },
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
        input: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {},
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            textTransform: 'none',
            transform: 'none',
            height: '56px',
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            background: themeColors.lightGrey,
            textTransform: 'none',
            transform: 'none',
            width: '100%',
            '&:hover': {
              background: themeColors.lightGrey,
            },
            disabled: { background: themeColors.lightGrey },
          },
        },
      ],
      styleOverrides: {
        root: {
          // background: themeColors.lightGrey,
          boxShadow: [
            'inset 0.8px 0.8px 1.6px rgba(255, 255, 255, 0.3), -0.8px -0.8px 1.6px rgba(216, 216, 219, 0.5)',
            'inset -4px 4px 8px rgba(216, 216, 219, 0.2), inset 4px -4px 8px rgba(216, 216, 219, 0.2)',
            'inset -4px -4px 8px rgba(255, 255, 255, 0.9)',
            'inset 4px 4px 10.4px rgba(216, 216, 219, 0.9)',
          ],
          borderRadius: '50px',
          fontFamily: 'Nasalization',
          // borderBottom: '0px solid transparent',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: '20px',
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          fontSize: '15px',
          color: themeColors.darkGrey,
          background: themeColors.lightGrey,
          boxShadow: [
            'inset 0.8px 0.8px 1.6px rgba(255, 255, 255, 0.3), -0.8px -0.8px 1.6px rgba(216, 216, 219, 0.5)',
            'inset -4px 4px 8px rgba(216, 216, 219, 0.2), inset 4px -4px 8px rgba(216, 216, 219, 0.2)',
            'inset -4px -4px 8px rgba(255, 255, 255, 0.9)',
            'inset 4px 4px 10.4px rgba(216, 216, 219, 0.9)',
          ],
          '&:hover': {
            background: themeColors.lightGrey,
          },
          cursor: 'pointer',
          borderRadius: '16.5px',
          borderBottom: '0px solid transparent',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: themeColors.primary,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          alignSelf: 'center',
          backgroundColor: '#F0F0F3',
          fontSize: '1rem',
          boxSizing: 'border-box',
          '&:hover': {
            boxShadow:
              '-4.13947px 4.13947px 8.27893px rgba(216, 216, 219, 0.2), 4.13947px -4.13947px 8.27893px rgba(216, 216, 219, 0.2), -4.13947px -4.13947px 8.27893px rgba(255, 255, 255, 0.9), 4.13947px 4.13947px 10.7626px rgba(216, 216, 219, 0.9), inset 0.827893px 0.827893px 1.65579px rgba(255, 255, 255, 0.3), inset -0.827893px -0.827893px 1.65579px rgba(216, 216, 219, 0.5)',
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        main: {
          fontFamily: 'Nasalization',
          fontStyle: 'normal',
          color: themeColors.chartTextGrey,
          textTransform: 'uppercase',
          WebkitBackgroundClip: 'text',
          paddingRight: '10px',
          border: `0px solid rgba(224, 224, 224, 1)`,
        },
        // '& Mui-selected': {
        //   background: themeColors.darkGrey,
        //   color: themeColors.white,
        //   border: 'none',
        // },
        cell: {
          border: 'none',
        },
        cellContent: {
          border: 'none',
        },
        row: {
          paddingTop: '5px',
          paddingBottom: '5px',
          backgroundColor: themeColors.lightGrey,
          borderRadius: '8px',
          boxShadow: `-4.13947px 4.13947px 8.27893px rgba(216, 216, 219, 0.2), 4.13947px -4.13947px 8.27893px rgba(216, 216, 219, 0.2), -4.13947px -4.13947px 8.27893px rgba(255, 255, 255, 0.9), 4.13947px 4.13947px 10.7626px rgba(216, 216, 219, 0.9), inset 0.827893px 0.827893px 1.65579px rgba(255, 255, 255, 0.3), inset -0.827893px -0.827893px 1.65579px rgba(216, 216, 219, 0.5)`,
          '&:hover': {
            background: themeColors.darkGrey,
            color: themeColors.white,
          },
          '&&:hover': {
            background: themeColors.darkGrey,
            color: themeColors.white,
          },
        },
        columnSeparator: { display: 'none' },
        columnHeaders: {
          marginBottom: '15px',
          backgroundColor: 'transparent',
          color: themeColors.lightGreyTable,
          display: 'flex',
          width: '100%',
          borderBottom: 'none',
          fontSize: '16px',
        },
        'row--editable': {
          border: 'none',
          background: themeColors.darkGrey,
          color: themeColors.white,
        },
      },
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
});
