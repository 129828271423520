import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { LandingPageProvider } from './state/LandingPageContext';
import { MajrLightTheme } from './theme/schemas/MajrLightTheme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={MajrLightTheme}>
      <BrowserRouter>
        <LandingPageProvider>
          <App />
        </LandingPageProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
