import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

export interface AuthState {
  isLoggedIn: boolean;
  isRegistered: boolean;
  token: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isRegistered: false,
  token:
    !!localStorage.getItem('token') &&
    localStorage.getItem('token') !== undefined &&
    localStorage.getItem('token') !== 'undefined'
      ? localStorage.getItem('token')
      : '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setIsRegistered: (state, action: PayloadAction<boolean>) => {
      state.isRegistered = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setIsLoggedIn, setIsRegistered, setToken } = authSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsRegistered = (state: RootState) => state.auth.isRegistered;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
