import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

export interface InfluencerState {
  email: string;
  name: string;
  token: string;
  majrWallet: string;
  wallet: string;
  refCode: string;
  twitter: string;
  creativeAssets: string;
  signUps: number;
  payouts: number;
  totalAmount: number;
  agencyId?: number;
}

const initialState: InfluencerState = {
  email: '',
  name: '',
  token: '',
  majrWallet: '',
  wallet: '',
  refCode: '',
  twitter: '',
  creativeAssets: '',
  signUps: 0,
  payouts: 0,
  totalAmount: 0,
  agencyId: 0,
};

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setInfluencerEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setRefCode: (state, action: PayloadAction<string>) => {
      state.refCode = action.payload;
    },
    setSignUps: (state, action: PayloadAction<number>) => {
      state.signUps = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<InfluencerState>) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.majrWallet = action.payload.majrWallet;
      state.wallet = action.payload.wallet;
      state.creativeAssets = action.payload.creativeAssets;
      state.signUps = action.payload.signUps;
      state.payouts = action.payload.payouts;
      state.totalAmount = action.payload.totalAmount;
      state.agencyId = action.payload.agencyId;
      state.twitter = action.payload.twitter;
      state.refCode = action.payload.refCode;
    },
  },
});

export const { setInfluencerEmail, setRefCode, setSignUps, setUserInfo } =
  influencerSlice.actions;

export const selectInfluencer = (state: RootState) => state.influencer;

export default influencerSlice.reducer;
