import React from 'react';

interface VideoGradientProps {
  active: boolean;
}
export const VideoGradient = ({ active }: VideoGradientProps) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={active ? 'url(#clip0_1667_1942)' : 'url(#clip0_1613_1267)'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.25 50C22.25 45.0294 26.2794 41 31.25 41H81.25C86.2206 41 90.25 45.0294 90.25 50V62.4996V87.4996V100C90.25 104.971 86.2206 109 81.25 109H31.25C26.2794 109 22.25 104.971 22.25 100V50ZM97.25 93.1622V100C97.25 108.837 90.0866 116 81.25 116H31.25C22.4134 116 15.25 108.837 15.25 100V50C15.25 41.1634 22.4134 34 31.25 34H81.25C90.0866 34 97.25 41.1634 97.25 50V56.8371L120.641 45.144L120.642 45.1438C122.128 44.4012 123.779 44.0506 125.439 44.1254C127.098 44.2001 128.711 44.6977 130.125 45.5709C131.538 46.4441 132.705 47.6639 133.514 49.1147C134.324 50.5654 134.749 52.199 134.75 53.8603V53.8621V96.1371V96.139C134.749 97.8003 134.324 99.4339 133.514 100.885C132.705 102.335 131.538 103.555 130.125 104.428C128.711 105.302 127.098 105.799 125.439 105.874C123.779 105.949 122.128 105.598 120.642 104.856L120.641 104.855L97.25 93.1622ZM97.25 85.3363L123.771 98.5938C124.19 98.8032 124.656 98.9021 125.124 98.881C125.592 98.8599 126.047 98.7196 126.445 98.4733C126.844 98.227 127.173 97.883 127.401 97.4738C127.63 97.0646 127.75 96.6039 127.75 96.1353V53.864C127.75 53.3954 127.63 52.9347 127.401 52.5255C127.173 52.1163 126.844 51.7723 126.445 51.526C126.047 51.2797 125.592 51.1393 125.124 51.1183C124.656 51.0972 124.19 51.196 123.771 51.4053L123.771 51.4055L97.25 64.663V85.3363Z"
          fill={
            active
              ? 'url(#paint0_linear_1667_1942)'
              : 'url(#paint0_linear_1613_1267)'
          }
        />
      </g>
      <defs>
        <linearGradient
          id={active ? 'paint0_linear_1667_1942' : 'paint0_linear_1613_1267'}
          x1="15.25"
          y1="16.7368"
          x2="120.186"
          y2="128.864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={active ? '#f9f9f9' : '#DE1149'} stopOpacity="0.2" />
          <stop
            offset="0.0001"
            stopColor={active ? '#f9f9f9' : '#DE1149'}
            stopOpacity={active ? '1' : '0.2'}
          />
          <stop
            offset="1"
            stopColor={active ? '#f9f9f9' : '#DE1149'}
            stopOpacity={active ? '0.2' : '0'}
          />
        </linearGradient>
        <clipPath id="clip0_1613_1267">
          <rect width="150" height="150" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
