import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from '../common/Header/Header';
import { Footer } from '../common/Footer/Footer';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { CustomRoutes } from './CustomRoutes';
import 'majr-react/dist/index.css';
import { HeaderProvider } from '../context/header';

const Toastify = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export const App = () => {
  return (
    <HeaderProvider>
      <Provider store={store}>
        <div style={{ width: '100%', height: '100%' }}>
          <Toastify />
          <Header />
          <CustomRoutes />
          <Footer />
        </div>
      </Provider>
    </HeaderProvider>
  );
};
