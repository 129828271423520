import { useNavigate } from 'react-router-dom';
import { useInfluencerApi } from '../../api/majr-api';
import styles from '../../styles/claim.module.scss';
// eslint-disable-next-line import/named
import { ethers } from 'ethers';
import { useState } from 'react';
import { Button, FlatBox, Input } from 'majr-react';
import { motion, AnimatePresence } from 'framer-motion';

type GetETHScreenProps = {
  children?: React.ReactNode;
};

type Inputs = {
  address: string;
};

export const GetETHScreen: React.FC<GetETHScreenProps> = () => {
  const navigate = useNavigate();

  const [isInvalid, setIsInvalid] = useState(false);
  const { handleRequestEth } = useInfluencerApi();

  const handleRequest = async (data: Inputs) => {
    let addressEth;
    try {
      addressEth = ethers.utils.getAddress(data.address);
      setIsInvalid(false);
      if (addressEth) {
        handleRequestEth(data.address).then(() => {
          navigate('/');
        });
      }
    } catch {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 3000);
    }
  };

  const [address, setAddress] = useState<string>('');
  const [confirm, setConfirm] = useState<boolean>(false);
  return (
    <section className={styles.claim}>
      <FlatBox width="20%" height="fit-content">
        <h2>Get ETH</h2>
        <Input
          value={address}
          onChange={setAddress}
          placeholder="Enter your ETH address"
        />
        <Button
          onClick={() => {
            setConfirm(true);
          }}
          text="Get ETH"
          variant="main"
          size="fillWidth"
        />
        <AnimatePresence>
          {isInvalid ? (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.error}
            >
              Invalid address
            </motion.p>
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {confirm ? (
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.confirmBackground}
            />
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          {confirm ? (
            <motion.section
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'fit-content' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.confirm}
            >
              <p>Is this the correct address?</p>
              <p>{address}</p>
              <section>
                <Button
                  onClick={() => {
                    setConfirm(false);
                    handleRequest({ address });
                  }}
                  text="Yes"
                  variant="main"
                />
                <Button
                  onClick={() => {
                    setConfirm(false);
                  }}
                  text="No"
                  variant="light"
                />
              </section>
            </motion.section>
          ) : null}
        </AnimatePresence>
      </FlatBox>
      <p>
        MAJR is not responsible for assets being sent to the wrong address.{' '}
        <br />
        <br />
        The input of the wrong address will result in the loss of assets.
      </p>
    </section>
  );
};
