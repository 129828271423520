import { TextField, InputLabel } from '@mui/material';
import { themeColors } from '../../theme/schemas/MajrLightTheme';

// eslint-disable-next-line @typescript-eslint/ban-types
type InputFieldProps = {
  className?: any;
  label?: string;
  value?: string | number | null;
  type?: string;
  width?: string;
  margin?: string | number;
  placeholder?: string;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCurrency?: boolean;
  isPercentage?: boolean;
  isDisabled?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
  className,
  label,
  placeholder,
  onChange,
  value,
  width,
  type,
  error,
  isCurrency,
  isPercentage,
  isDisabled,
  margin,
}) => {
  return (
    <div style={{ width, margin }}>
      <InputLabel htmlFor="input-with-icon-adornment">{label}</InputLabel>
      <TextField
        disabled={isDisabled}
        variant="standard"
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        type={type}
        helperText={error}
        style={{
          paddingLeft: isCurrency ? '5px' : '0px',
          marginBottom: '10px',
          width: '100%',
          border: !!error ? '1px solid #DE1149' : 'none',
        }}
        InputProps={{ disableUnderline: true }}
      />
      {isCurrency && (
        <div
          style={{
            position: 'relative',
            bottom: 45,
            left: '5%',
            zIndex: 2,
            width: '10px',
            height: '10px',
            color: themeColors.darkGreyText,
          }}
        >
          $
        </div>
      )}
      {isPercentage && (
        <div
          style={{
            position: 'relative',
            bottom: 45,
            left: '85%',
            zIndex: 2,
            width: '10px',
            height: '10px',
            color: themeColors.darkGreyText,
          }}
        >
          %
        </div>
      )}
    </div>
  );
};

InputField.defaultProps = {
  className: '',
  label: '',
  placeholder: '',
  value: '',
  type: 'text',
  onChange: () => {},
  isCurrency: false,
  isDisabled: false,
};
