import React, { createContext, useState, useMemo, useCallback } from 'react';
type StateType = 'Login' | 'Sign Up' | 'Logged In';
interface HeaderContextProps {
  state: StateType;
  changeState: (newState: StateType) => void;
  drawer: boolean;
  setDrawer: (newState: boolean) => void;
}

export const HeaderContext = createContext<HeaderContextProps>({
  state: 'Login',
  changeState: () => {},
  drawer: false,
  setDrawer: () => {},
});

export const HeaderProvider: React.FC<
  React.PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const [state, setState] = useState<StateType>('Login');
  const [drawer, setDrawer] = useState<boolean>(false);

  const changeState = useCallback((newState: StateType) => {
    setState(newState);
  }, []);

  const value = useMemo(
    () => ({ state, changeState, drawer, setDrawer }),
    [state, changeState, drawer, setDrawer]
  );

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};
