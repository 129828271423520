import React from 'react';
import { HeaderContext } from '../../context/header';
import styles from '../../styles/header.module.scss';
import { IconSettings } from '@tabler/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Input } from 'majr-react';

export const Drawer = () => {
  const { drawer, setDrawer } = React.useContext(HeaderContext);

  const [email, setEmail] = React.useState<string>('');
  const [address, setAddress] = React.useState<string>('');
  const [bankAccount, setBankAccount] = React.useState<string>('');
  const [walletAddress, setWalletAddress] = React.useState<string>('');

  return (
    <>
      <AnimatePresence>
        {drawer ? (
          <motion.section
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: '30%' }}
            exit={{ opacity: 0, width: 0 }}
            className={styles.drawer}
          >
            <h3>
              Settings <IconSettings color="#38383D" size={40} />
            </h3>
            <p>Email</p>
            <Input
              placeholder="Enter a new email"
              value={email}
              onChange={setEmail}
              variant="light"
              disabled={true}
            />
            <p>Address</p>
            <Input
              placeholder="Enter a new address"
              value={address}
              onChange={setAddress}
              variant="light"
              disabled={true}
            />
            <p>Bank Account</p>
            <Input
              placeholder="Enter a new bank account"
              value={bankAccount}
              onChange={setBankAccount}
              variant="light"
              disabled={true}
            />
            <p>Wallet Address</p>
            <Input
              placeholder="Enter a new wallet address"
              value={walletAddress}
              onChange={setWalletAddress}
              variant="light"
              disabled={true}
            />

            <Button
              text="Save"
              size="fillWidth"
              variant="main"
              onClick={() => setDrawer(false)}
            />
          </motion.section>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {drawer ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.drawerBackground}
            onClick={() => setDrawer(false)}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};
