import { Snackbar, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../../common/InputField/InputField';
import { CustomButton } from '../../../common/CustomButton/CustomButton';
import { themeColors } from '../../../theme/schemas/MajrLightTheme';
import { Layout } from '../../../common/Layout';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectIsRegistered,
  setIsLoggedIn,
  setIsRegistered,
  setToken,
} from '../../../features/authSlice';
import { useInfluencerApi } from '../../../api/majr-api';
// eslint-disable-next-line import/named
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

type AgenciesLoginScreenProps = {
  children?: React.ReactNode;
};
type Inputs = {
  email: string;
  password: string;
};

export const AgenciesLoginScreen: React.FC<AgenciesLoginScreenProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const isRegistered = useAppSelector(selectIsRegistered);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { loginAgency } = useInfluencerApi();
  const [open, setOpen] = useState(isRegistered || false);
  const [wrongLogin, setWrongLogin] = useState(false);

  const handleSignIn = async (data: Inputs) => {
    await loginAgency(data.email, data.password)
      .then((res: { token: string }) => {
        localStorage.setItem('token', res.token);
        dispatch(setIsLoggedIn(true));
        dispatch(setToken(res.token));
        navigate('/rewards');
      })
      .catch((err: unknown) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setWrongLogin(true);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await handleSignIn(data);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setIsRegistered(false));
    setOpen(false);
  };

  return (
    <Layout
      height={isMobile ? '62vh' : '72vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '95%' : '25%',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            alignSelf: 'flex-start',
            color: themeColors.darkGreyText,
            fontSize: isMobile ? '16px' : '24px',
            textTransform: 'uppercase',
            fontFamily: 'Nasalization',
            fontWeight: 100,
            height: isMobile ? '24px' : '40px',
            marginLeft: isMobile ? '20px' : '10px',
            textAlign: 'left',
          }}
        >
          Sign In - Agency
        </Typography>
        {errors.email && (
          <span
            role="alert"
            style={{
              color: themeColors.primary,
              margin: '0px',
              marginBottom: '5px',
            }}
          >
            Email is required
          </span>
        )}
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <InputField
              width={'100%'}
              margin={'0px 20px 20px 15px'}
              placeholder={'Email'}
              {...field}
            />
          )}
        />

        {errors.password && (
          <span
            role="alert"
            style={{
              color: themeColors.primary,
              margin: '0px',
              marginBottom: '5px',
            }}
          >
            Password is required
          </span>
        )}
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <InputField
              type="password"
              width={'100%'}
              margin={'0px 20px 20px 15px'}
              placeholder={'Password'}
              {...field}
            />
          )}
        />

        <CustomButton textAlign={'center'} title={'Login'} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: isMobile ? '20px' : '15px',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            style={{
              alignSelf: 'flex-start',
              color: themeColors.primary,
              fontSize: isMobile ? '12px' : '14px',
              textTransform: 'uppercase',
              fontFamily: 'Nasalization',
              fontWeight: 100,
              cursor: 'pointer',
              height: isMobile ? '24px' : '40px',
              marginRight: isMobile ? '20px' : '0px',
            }}
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </Typography>

          <Typography
            variant="body1"
            style={{
              alignSelf: 'flex-start',
              color: themeColors.darkGreyText,
              fontSize: isMobile ? '12px' : '14px',
              textTransform: 'uppercase',
              fontFamily: 'Nasalization',
              fontWeight: 100,
              cursor: 'pointer',
              height: isMobile ? '24px' : '40px',
              marginRight: isMobile ? '20px' : '0px',
            }}
            onClick={() => navigate('/agencies/sign-up')}
          >
            Sign Up
          </Typography>
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Account created successfully!"
      />
      <Snackbar
        open={wrongLogin}
        autoHideDuration={2000}
        onClose={() => setWrongLogin(false)}
        message="Please check your login credentials!"
      />
    </Layout>
  );
};
