import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useInfluencerApi } from '../../api/majr-api';
import { Button, FlatBox, Input } from 'majr-react';
import styles from '../../styles/login.module.scss';

type LoginScreenProps = {
  children?: React.ReactNode;
};
type Inputs = {
  email: string;
};

export const ForgotPasswordScreen: React.FC<LoginScreenProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const navigate = useNavigate();

  const { forgotPassword } = useInfluencerApi();

  const handleForgotPassword = async (data: Inputs) => {
    await forgotPassword(data.email)
      .then(() => {
        navigate('/');
      })
      .catch((err: unknown) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const [email, setEmail] = React.useState<string>('');
  return (
    <section className={styles.forgotContainer}>
      <FlatBox
        // onSubmit={handleSubmit(onSubmit)}
        width={isMobile ? '90%' : '20%'}
        height="fit-content"
        className={styles.forgot}
      >
        <h3>Forgot password</h3>
        <Input
          onChange={setEmail}
          value={email}
          placeholder="Enter your email"
        />
        <Button
          onClick={() => {
            handleForgotPassword({ email });
          }}
          text="Reset password"
          variant="main"
          size="fillWidth"
        />
      </FlatBox>
    </section>
  );
};
