import React, { useState } from 'react';

type LandingPageProviderProps = {
  children?: React.ReactNode;
};

export const LandingPageContext = React.createContext({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => {},
});

export const LandingPageProvider: React.FC<LandingPageProviderProps> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <LandingPageContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </LandingPageContext.Provider>
  );
};
